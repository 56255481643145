<template>
  <div>
    <div class="search" v-if="role != 4" @click="searchShow = true"><van-icon name="search" color="#fff" size="30"/></div>
    <van-popup v-model="searchShow" closeable  position="right" style="width: 80vw; height: 100vh;" get-container=".container">
      <!-- get-container=".container" -->
      <div class="searchForm">
        <b>查询</b>
        <div class="searchMain">
          <p>机构名称：</p>
          <el-select ref="agentSelect" v-model="orgId" :popper-append-to-body="false" filterable
              placeholder="选择机构名称" @change="handleChange">
              <el-option v-for="item in inspectList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
          </el-select>
          <p>所属省市区：</p>
          <el-select ref="agentSelect" v-model="provinceCode" :popper-append-to-body="false" filterable
              placeholder="选择省" @change="getCity">
            <el-option v-for="item in options1" :key="item.code" :label="item.text" :value="item.code"></el-option>
          </el-select>
          <el-select ref="agentSelect" v-model="cityCode" :popper-append-to-body="false" filterable
              placeholder="选择市" @change="getArea">
            <el-option v-for="item in options2" :key="item.code" :label="item.text" :value="item.code"></el-option>
          </el-select>
          <el-select ref="agentSelect" v-model="areaCode" :popper-append-to-body="false" filterable
              placeholder="选择区县">
            <el-option v-for="item in options3" :key="item.code" :label="item.text" :value="item.code"></el-option>
          </el-select>
        </div>
        <div class="btn">
          <p @click="search">查询</p>
          <p @click="reset">重置</p>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="cityShow" closeable  position="bottom" style="width: 100vw; height: 60vh;" get-container=".container" >
      <van-picker show-toolbar :columns="options1" />
    </van-popup>
  </div>
</template>
<script>

export default {
  name: "Main",
  data() {
    const that = this;
    return {
      role:0,
      searchShow: false,
      orgId: '',
      inspectList: [],

      cityShow:false,
     
      provinceCode: '',
      cityCode: '',
      areaCode: '',

      options1:[],
      options2:[],
      options3:[],

      loading: false,
      props: {
        checkStrictly:true,
          lazy: true,
          value:'code',
          label:'text',
          lazyLoad :async (node, resolve)=>{
            console.log(node)
            const { level,value } = node;
            console.log(level)
            if(level == 1){
              console.log(value)
              await this.getCity(value)
              const nodes = this.options2.map(item=>{
                let param = item
                param.leaf = false
                return param
              })
              resolve(nodes)
            }else if(level == 2){
              await this.getArea(value)
              const nodes = this.options3.map(item=>{
                let param = item
                param.leaf = true
                return param
              })
              resolve(nodes)
            }else{
              resolve([])
            }
            
          }
        },
    };
  },
  computed: {},
  watch: {

  },

  async mounted() {
    this.role = localStorage.getItem('role')
    
    this.getOrg()
    this.getProvince()
  },
  actived() {

  },
  methods: {
 
    async getProvince(){
      let res = await this.$api({
        method: "get",
        url: "/api/province",
      });
      if (res && res.code == 0) {
        this.options1=res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getCity(e) {
      this.cityCode = ''
      this.areaCode = ''
      this.options2 = []
      this.options3 = []
      this.loading = true
      let res = await this.$api({
        method: "get",
        url: "/api/city",
        data: {
          q: e
        }
      });
      this.loading = false
      if (res.code == 0) {
        this.options2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getArea(e) {
      this.areaCode = ''
      this.options3 = []
      this.loading = true
      let res = await this.$api({
        method: "get",
        url: "/api/area",
        data: {
          q: e
        }
      });
      this.loading = false
      if (res.code == 0) {
        this.options3 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getOrg(){
      let res = await this.$api({
          method: "get",
          url: "/api/organ/list",
          data: {
              page: 1,
              limit: 1000,
          }
      });
      if (res.code == 0) {
          this.inspectList = res.data.list
      } else {
          this.$message({
              message: res.msg,
              type: "warning",
          });
      }
    },
    handleChange(id) {
        this.orgId = id
    },
    reset(){
      this.orgId = ''
      this.provinceCode = ''
      this.cityCode = ''
      this.areaCode = ''
    },
    search(){
      let info = {}
      if(this.orgId){
        info.organization_id = this.orgId
      }
      if(this.areaCode){
        info.code = this.areaCode
        info.code_type = 3
      }else if(this.cityCode){
        info.code = this.cityCode
        info.code_type = 2
      }else if(this.provinceCode){
        info.code = this.provinceCode
        info.code_type = 1
      }
      this.searchShow = false
      this.$emit('toSearch',info)
    }
  },
};
</script>
<style lang="scss" scoped>
.searchForm{
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .searchMain{
    flex: 1;
    >p{
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  .btn{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    p{
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #efefef;
    }
    p:first-of-type{
      background-color: #0083F5;
      border: 1px solid #0083F5;
      color: #fff;
      margin-right: 20px;
    }
  }
  
  ::v-deep .el-select{
    width: 100%;
    margin-bottom: 10px;
  }
  ::v-deep .el-select-dropdown{
    left: 20px !important;
    width: calc(100% - 40px);
  }
}
</style>